import React from "react";
import { graphql, Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import styles from "./project.module.css";
import Layout from "../components/Layout";

function Project({ data }) {
  const {
    projectTitle,
    projectDescription,
    headerPhoto,
    photos,
  } = data.contentfulProject;

  return (
    <Layout location="Home">
      <Link to="/">&#8592; Back to home</Link>

      <h1 className={styles.title}>{projectTitle}</h1>

      {documentToReactComponents(projectDescription.json)}

      <section className={styles.photos}>
        <img alt="" src={headerPhoto.file.url} />

        {photos.map((photo, idx) => {
          return <img key={idx} alt="" src={photo.file.url} />;
        })}
      </section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query pageQuery($contentful_id: String!) {
    contentfulProject(contentful_id: { eq: $contentful_id }) {
      projectTitle
      projectDescription {
        json
      }
      headerPhoto {
        file {
          url
        }
      }
      photos {
        file {
          url
        }
      }
    }
  }
`;

export default Project;
